import Vue from 'vue'
import Chat from '../components/chat.vue'
import underscore from 'vue-underscore'
Vue.use(underscore)
document.addEventListener('DOMContentLoaded', () => {
  if(document.getElementById('chat')) {
    window.chat = new Vue({
       el: '#chat',
      render: h => h(Chat)
    });
  }
});
