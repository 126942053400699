<template>
  <div class="chat__conversation__out">
    <section class="chat__conversation" v-bind:class="{'chat__conversation--minimalize': minimalize}"v-bind:data-conversation="conversation.id" v-on:click="makeActive">
      <header class="chat__conversation__header" v-bind:class="{'chat__conversation__header--active': active}">
        <div class="chat__row__user" v-bind:class="['chat__row__user--'+conversation.user.user_type.type]" v-bind:style="userBackground(conversation.user)"></div>
        <div class="chat__conversation__controls">
          <button v-if="minimalize" type="button" class="btn btn-link btn-sm" v-on:click.prevent="showConversation">
            <i class="fas fa-window-restore"></i>
          </button>
          <button type="button" class="btn btn-link btn-sm chat__conversation__close" v-on:click.stop="closeConversation">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="chat__conversation__header__info">
          <div class="chat__conversation__header__profile">
            <span>{{conversation.user.name}}</span>
            <div>
              <span v-if="conversation.user.team">{{conversation.user.user_type.label}} <span >{{i18n('offer.in')}} {{conversation.user.team.name}}</span></span>
              <span v-else>{{conversation.user.user_type.label}}</span>
              <br />
              <span v-if="conversation.user.licence_number">{{i18n('offer.licence_number')}}: {{conversation.user.licence_number}}</span>
            </div>
          </div>
          <div class="chat__conversation__header__ribbon">{{conversation.ad.ribbon}}</div>
          <div class="chat__conversation__header__address">
            <a v-bind:href="langtag_path+'/catalog/offer/'+conversation.ad.id" v-on:click.prevent="openAd()" data-toggle="tooltip" v-bind:title="i18n('chat.open_offer')">{{conversation.ad.ad_addresses[0].place.short_name}}</a>
          </div>
          <div class="chat__conversation__header__price">
            {{conversation.ad.price}}
          </div>
        </div>
        <a v-bind:href="langtag_path+'/catalog/offer/'+conversation.ad.id" v-on:click.prevent="openAd()" data-toggle="tooltip" v-bind:title="i18n('chat.open_offer')" class="chat__conversation__header__image" v-bind:style="photoBackground(conversation.ad)" v-bind:class="{'offer__mainimage--no-photo': (!conversation.ad.main_photo && conversation.ad.is_offer), 'offer__mainimage--searching': (!conversation.ad.main_photo && !conversation.ad.is_offer)}"></a>

      </header>
      <ul v-if="!minimalize" ref="content" class="list-unstyled chat__messages" v-bind:class="{'chat__messages--small': (images.length > 0)}" v-bind:id="'messages_'+conversation.id">
        <li class="chat__message" v-for="message, message_index in messages">
          <div class="chat__row__user" v-if="!message.user.is_current" v-bind:class="['chat__row__user--'+message.user.user_type.type, ((message_index > 0 && messages[message_index-1].user.id == message.user.id) ? 'chat__row__user--invisible' : '')]" v-bind:style="userBackground(message.user)"></div>
          <div class="chat__message__content" v-bind:class="['chat__message__content--'+message.user.user_type.type, 'chat__message__content--'+(message.user.is_current ? 'right' : 'left')]">
            <p>
              <a href="#" v-for="image, image_index in message.images" class="chat__image" v-bind:style="{backgroundImage: 'url(\''+image.url+'\')'}" v-on:click.prevent="displayImage(image.index)"></a>
              <span v-if="message.message"v-html="message.message">{{message.message}}</span>
            </p>
            <small>{{message.created_at_time}}</small>
          </div>
        </li>
      </ul>
      <form v-if="!minimalize && conversation.status >= -1" class="chat__form" v-bind:class="{'chat__form--images': (images.length > 0)}" v-on:submit.prevent="sendMessage" ref="messageForm">
        <div class="chat__form__content">
            <div v-if="images.length > 0" class="chat__form__images">
              <div v-for="image, image_index in images" class="chat__form__image" v-bind:style="{backgroundImage: 'url(\''+image.url+'\')'}">
                <button type="button" class="btn btn-link chat__form__image__remove" v-on:click.prevent="removeImage(image_index)"></button>
              </div>
            </div>
        </div>
        <div class="btns">
          <file-upload
            ref="upload_message"
            :data="{message: message}"
            :thread="1"
            v-model="images"
            :multiple="true"
            @input-filter="inputFilter"
            v-bind:input-id="'file_chact_'+conversation.id"
          >
            <button type="button" class="btn btn-sm btn-link">
              <i class="fas fa-camera"></i>
            </button>
          </file-upload>
          <div class="text-area">
            <textarea rows="1" class="form-control" v-bind:class="{'ellipsis': (message == null || message.length == 0)}" ref="message" v-model="message" v-on:keyup="checkTextHeight()" v-on:keyup.enter="sendMessage" v-bind:placeholder="i18n('chat.message')"></textarea>
          </div>
          <button type="submit" class="btn btn-sm btn-link btn-send">
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </form>
      <div class="chat__form d-flex justify-content-center align-items-center" v-if="conversation.status == -2">
        <div v-if="conversation.user.blocked">
          {{i18n('chat.you_blocked')}}
          <a href="#" v-on:click.prevent="$emit('unblockUser', conversation.id)">
            <i class="fas fa-unlock"></i>
          </a>
        </div>
        <div v-else>
          {{i18n('chat.you_are_blocked')}}
        </div>
      </div>
    </section>
    <!-- <div class="offer__gallery" v-if="gallery_open">
      <button type="button" v-on:click.prevent="closeGallery" class="close"><span aria-hidden="true">&times;</span></button>
      <div class="offer__gallery__image-wraper">
        <figure class="offer__gallery__image">
          <img v-bind:src="gallery[gallery_index].full_url">
        </figure>
      </div>
      <button class="offer__gallery__goleft" v-bind:class="{'d-none': (gallery_index == 0)}" v-on:click.prevent="prevPhoto"></button>
      <button class="offer__gallery__goright" v-bind:class="{'d-none': (gallery_index == (gallery.length - 1))}" v-on:click.prevent="nextPhoto"></button>
    </div> -->

    <div class="offer__gallery" v-if="gallery_open" v-on:click.self="closeGallery">
      <button type="button" v-on:click.prevent="closeGallery" class="close"><span aria-hidden="true">&times;</span></button>
      <div class="offer__gallery__image-wraper"  v-on:click.self="closeGallery" v-bind:style="{'width': ((gallery.length*80))+'%', left: (10-(gallery_index*80))+'%'}" v-touch:swipe.left="nextPhoto" v-touch:swipe.right="prevPhoto">
        <div class="offer__gallery__image" v-on:click.self="closeGallery" v-for="(photo, index) in gallery" v-bind:style="{opacity: (gallery_index ==  index? 1 : 0), width: (100/gallery.length)+'%'}">
          <figure v-on:click.self="closeGallery">
            <img v-bind:src="photo.full_url" v-bind:alt="photo.ad_photo_section" v-bind:style="{'left': (gallery_index ==  index ? gallery_image_left*-1 : 0)+'px', position: 'relative'}">
          </figure>
        </div>
      </div>
      <button class="offer__gallery__goleft" v-bind:class="{'d-none': ((gallery_index == 0) && !gallery_move_left), 'panorama': gallery_move_left}" v-on:click.prevent="prevPhoto"></button>
      <button class="offer__gallery__goright" v-bind:class="{'d-none': (gallery_index == (gallery.length - 1) && !gallery_move_right), 'panorama': gallery_move_right}" v-on:click.prevent="nextPhoto"></button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import i18nmixin from '../customs/i18nmixin'
import createChannel from "../customs/cable"
import FileUpload from 'vue-upload-component'
import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Vue2TouchEvents);
export default {
  name: 'chat-conversation',
  mixins: [i18nmixin],
  props: ['conversation', 'right', 'active'],
  components: {
    FileUpload
  },
  data: function() {
    return {
      messages: [],
      message: null,
      images: [],
      gallery: [],
      langtag_path: window.langtag_path,
      minimalize: false,
      gallery_open: false,
      gallery_index: 0,
      gallery_move_left: false,
      gallery_move_right: false,
      gallery_image_width: null,
      gallery_figure_width: null,
      gallery_image_left: 0,
      bese_height: 0,
    };
  },
  mounted: function() {
    this.fetchMessages();
    this.createChannel();
    this.bese_height = this.$refs.message.scrollHeight;
  },
  watch: {
    active: function(val, old) {
      if(val == true && !old && this.conversation.unreaded > 0) {
        this.conversationRead();
      }
      if(val == true && !old && this.minimalize) {
        this.showConversation();
      }
    }
  },
  methods: {
    fetchMessages() {
      var self = this;
      window.axios.get(window.langtag_path+'/api/conversation_messages.json', {
        params: {
          c_id: this.conversation.id
        }
      })
      .then(function(response) {
        if(self.messages.length == 0) {
          self.messages = response.data;
          for(var i = 0; i < self.messages.length; i++) {
            for(var j = 0; j < self.messages[i].images.length; j++) {
              self.messages[i].images[j].index = self.gallery.length;
              self.gallery.push(self.messages[i].images[j]);
            }
          }
        }
        setTimeout(self.scrollDown, 0);
      })
    },
    checkTextHeight() {
      console.log(['base height',  this.bese_height]);
      this.$refs.message.style.height = this.bese_height+"px";
      this.$refs.message.style.height = Math.min((this.bese_height)*5, Math.max(this.$refs.message.scrollHeight, this.bese_height))+'px';
      this.$refs.content.style.bottom = this.$refs.messageForm.offsetHeight+'px';
    },
    sendMessage(e) {
      if(e.shiftKey) {
        return true;
      }
      if(this.message) {
        this.message = this.message.trim();
        this.checkTextHeight();
      }
      console.log(['key', e, e.shiftKey, (!this.message && this.images.length == 0), !this.message, this.message, this.images.length == 0]);
      if(!this.message && this.images.length == 0) {
        return true;
      }
      // this.$refs.upload_message.active = true
      var self = this;
      var formData = new FormData();
      formData.append("message", (this.message ? this.message : ''));
      formData.append("c_id", this.conversation.id);
      for(var i = 0; i < this.images.length; i++) {
        formData.append("images[]", this.images[i].file);
      }
      window.axios.post(window.langtag_path+'/api/conversation_messages.json', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }})
      .then(function(response) {
        self.message = null;
        self.images = [];
        setTimeout(function() {
          self.checkTextHeight();
          self.scrollDown();
        }, 40);
      })
    },
    conversationRead() {
      var self = this;
      window.axios.post(window.langtag_path+'/api/conversation_messages/read.json', {
        c_id: this.conversation.id
      })
      .then(function(response) {
        // In any case
      })
    },
    scrollDown() {
      if(!this.minimalize) {
        var container = document.getElementById('messages_'+this.conversation.id);
        if(container) {
          container.scrollTop = container.scrollHeight;
        }
        if(container && this.active && this.conversation.unreaded > 0) {
          this.conversationRead();
        }
      }
    },
    userBackground(user) {
      if(user.photo_url) {
        return {'background-image': 'url(\''+user.photo_url+'\')'};
      }
    },
    photoBackground(ad) {
      if(ad.main_photo) {
        return {'background-image': 'url(\''+ad.main_photo+'\')'};
      }
    },
    closeConversation() {
      this.$emit('closeConversation', this.conversation.id);
    },
    closeChat() {
      this.$emit('toggleChat');
    },
    makeActive() {
      if(!this.active && !this.minimalize) {
        this.$emit('openConversation', this.conversation);
        this.conversationRead();
      }
    },
    createChannel() {
      var self = this;
      var channel = createChannel({channel: "ChatMessageChannel", conversation_id: this.conversation.id}, {
        received(data) {
          window.axios.get(window.langtag_path+'/api/conversation_messages/'+data.message_id+'.json', {
            params: {
              c_id: self.conversation.id
            }
          })
          .then(function(response) {
            for(var i = 0; i < self.messages.length; i++) {
              if(self.messages[i].id == response.data.id) {
                return; // Already exists
              }
            }
            for(var j = 0; j < response.data.images.length; j++) {
              response.data.images[j].index = self.gallery.length;
              self.gallery.push(response.data.images[j]);
            }
            self.messages.push(response.data);
            setTimeout(self.scrollDown, 0);
          })
        }
      });
    },
    minimizeConversation() {
      this.minimalize = true;
    },
    showConversation() {
      this.minimalize = false;
      setTimeout(this.scrollDown, 0);
    },
    remove() {
      this.$emit('removeConversation', this.conversation.id);
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          toastr.error(i18n('errors.file_type'));
          return prevent()
        }
        // Create a blob field
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
      var self = this;
      setTimeout(function() {
        self.$refs.content.style.bottom = self.$refs.messageForm.offsetHeight+'px';
        self.scrollDown();
      }, 40);

    },
    removeImage(image_index) {
      this.images.splice(image_index, 1);
      var self = this;
      setTimeout(function() {
        self.$refs.content.style.bottom = self.$refs.messageForm.offsetHeight+'px';
        self.scrollDown();
      }, 40);
    },
    displayImage(index) {
      this.gallery_open = true;
      this.gallery_index = index;
    },
    closeGallery() {
      this.gallery_open = false;
    },
    nextPhoto() {
      if(this.gallery_index < this.gallery.length-1 && this.gallery_move_right == false) {
        this.gallery_index++;
        this.gallery_image_left = 0;
        this.gallery_move_right = false;
        this.gallery_move_left = false;
      } else if (this.gallery_move_right) {
        // this.gallery_image_left = Math.min(this.gallery_image_left+this.gallery_figure_width, this.gallery_image_width-this.gallery_figure_width);
        var destination = Math.min(this.gallery_image_left+this.gallery_figure_width, this.gallery_image_width-this.gallery_figure_width);
        this.animatePanoramic(destination);
        this.gallery_move_left = true;
        if(this.gallery_figure_width+destination>= this.gallery_image_width) {
          this.gallery_move_right = false;
        }
      }
    },
    prevPhoto() {
      if(this.gallery_index > 0 && this.gallery_move_left == false) {
        this.gallery_index--;
        this.gallery_image_left = -1;
        this.gallery_move_right = false;
        this.gallery_move_left = false;
      } else if (this.gallery_move_left) {
        // this.gallery_image_left = Math.max(this.gallery_image_left-this.gallery_figure_width, 0);
        var destination = Math.max(this.gallery_image_left-this.gallery_figure_width, 0);
        this.animatePanoramic(destination);
        this.gallery_move_right = true;
        if(destination <= 0) {
          this.gallery_move_left = false;
        }
      }
    },
    animatePanoramic(destination) {
      var current = this.gallery_image_left;
      var diff = destination-current;
      var perStep = diff/25;
      var self = this;
      for(let i = 1; i <=25; i++) {
        setTimeout(function(){
          self.gallery_image_left = current+perStep*i;
        }, 10*i);
      }
    },
    openAd() {
      if(this.isMobile()) {
        this.closeConversation();
        this.closeChat();
      }
      window.location.href = langtag_path+'/catalog/offer/'+this.conversation.ad.id;
    },
    isMobile() {
      var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      return w <= 991;
    }
  }
}
</script>
